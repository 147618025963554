import React from "react";
import Sidenav from "./Sidenav";

export default function FreeComponent() {
    return (
        <div>
            <Sidenav/>
            <h1 className="text-center">Free Component</h1>
        </div>
    );
}
